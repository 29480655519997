import styled from "styled-components";
import { Link } from "gatsby";

const Title = styled.h1`
  text-shadow: rgba(255, 255, 255, 0.15) 0px 5px 35px;
  font-size: 2rem;
  line-height: 1.1;
  @media (min-width: 768px) {
    font-size: 2.8rem;
    line-height: 1.2;
  }
  padding: 8px 0;
  font-family: inherit;
  font-weight: 800;
`;

const TitleContainer = styled.div`
  margin-bottom: 16px;
  h1 {
    margin: 0 auto;
  }
`;

const SubTitle = styled.p`
  color: #e2e8f0;
  padding-bottom: 16px;
  @media (min-width: 768px) {
    font-size: 125%;
  }
  letter-spacing: -0.003em;
  --baseline-multiplier: 0.179;
  --x-height-multiplier: 0.35;
`;

const TextInput = styled.input`
  width: 100%;
  max-width: 300px;
  padding: 12px;
  border: 0px solid #ccc;
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius-box);
  font-size: 1rem;
  font-weight: 400;
  background-color: #fafafa;
  color: #121212;
  outline: none;
  -webkit-appearance: none;
  box-sizing: border-box;
`;

const buttonStyle = `
  margin: 0 auto;
  background-color: var(--primary);
  color: var(--white);
  text-decoration: none;
  cursor: pointer;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: var(--border-radius);
  font-weight: 700;
  font-size: 14px;
  text-transform: uppercase;
  border: none;
  transition: all 0.1s ease-out;
  width: 100%;
  max-width: 300px;
  padding: 12px 6px;
  display: inline-block;
  text-align: center;

  @media (min-width: 768px) {
    padding: 16px 8px;
  }

  svg {
    height: 24px;
    fill: var(--white);
    margin-right: 8px;
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover,
    &:focus {
      background-color: var(--primary-invert);
      color: var(--black);
      svg {
        fill: var(--black);
      }
    }
  }
  :disabled {
    cursor: not-allowed;
    background-color: #333;
    color: #666;
  }
`;
const StyledButton = styled.button`
  ${buttonStyle}
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "var(--primary)"};
`;
const StyledButtonLink = styled(Link)`
  ${buttonStyle}
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "var(--primary)"};
`;
const FloatButtonLink = styled(Link)`
  ${buttonStyle}
  border: 4px solid #333;
  display: flex;
  justify-content: center;
  padding: 8px;
  box-shadow: var(--box-shadow);

  span {
    margin: auto 0;
    line-height: 1;
  }
`;

const ButtonContainer = styled.div`
  margin: 16px auto;
`;

const Badge = styled.div`
  margin: 8px;
  width: max-content;
  border-radius: var(--border-radius-box);
  padding: 4px 8px;
  margin: 0 auto;
  text-transform: uppercase;
  text-align: center;
  font-size: 0.8rem;
  font-weight: ${(props) => (props.bold ? "800" : "400")};
  background-color: ${(props) => props.backgroundColor};
  display: flex;
  justify-content: center;
  span {
    color: #000;
    line-height: 1;
    padding: 4px 8px;
    font-size: 12px;
    margin: auto 0;
  }
  svg {
    border-radius: var(--border-radius);

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        cursor: pointer;
        background-color: #fff;
      }
    }
    height: 16px;
    padding: 8px;
  }
`;

const BadgeSpan = styled.span`
  background-color: var(--primary);
  border-radius: var(--border-radius-box);
  color: var(--anti-flash-white);
  display: block;
  font-size: 12px;
  padding: 3px 6px;
  text-align: center;
  text-transform: uppercase;
  width: max-content;
`;

const UserImage = styled.img`
  border-radius: 50%;
  width: 32px;
  height: 32px;
`;

export {
  Title,
  TitleContainer,
  SubTitle,
  TextInput,
  StyledButton,
  StyledButtonLink,
  ButtonContainer,
  FloatButtonLink,
  Badge,
  BadgeSpan,
  UserImage,
};
