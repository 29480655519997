import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
  html {
    box-sizing: border-box;
    font-size: 16px;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  body, h1, h2, h3, h4, h5, h6, p, ol, ul {
    margin: 0;
    padding: 0;
    font-weight: normal;
  }

  a {
    color: var(--primary);
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: none;
  }

  ol, ul {
    list-style: none;
  }

  img {
    max-width: 100%;
    height: auto;
    display: block;
  }

  body {
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
      Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    line-height: 1.6;
    font-size: 18px;
    margin: 0 auto;
    max-width: 1024px;
    background-color: #141821;
    color: #fff;
    text-align: center;
    svg.logo {
      height: 64px;
      @media (min-width: 768px) {
        height: 64px;
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover,
      &:focus {
          path {
            transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
            fill: #757aff;
          }
        }
      }
    }

    --yellow: #e8c547;
    --lighter: #4d5061;
    --blue: #5c80bc;
    --pastel-gray: #cdd1c4;
    --azure-white: #cdedf6;
    --red: #da4167;
    --green: #28afb0;
    --light-sea-green: #28afb0;
    --dark-sea-green: #87bba2;
    --aquamarine-green: #71f79f;

    --primary-invert: #ebe7de;
    --error: #ea4c89;
    --black: #000000;
    --black-light: #212121;
    --grey-x-dark: #474a51;
    --grey-dark: #757575;
    --grey-medium: #e0e0e0;
    --grey-light: #f5f5f5;
    --grey-lightest: #fafafa;
    --grey: #9e9e9e;
    --white: #ffffff;
    --anti-flash-white: #edf2f4;
    --primary: #757aff;
    --primary-text: #f7b733;
    --primary-lightest: #ffefc1;
    --primary-light-alpha: rgba(255, 239, 193, 0.5);
    --primary-light: #b0b3ff;
    --primary-dark: #121212;
    --border-radius: 8px;
    --border-radius-box: 4px;
    --box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.10), 0 0 6px rgba(0, 0, 0, 0.10);
  }
`;
