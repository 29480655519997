import React from "react";
import PropTypes from "prop-types";

import AppStyles from "./app-styles";

const Layout = ({ children }) => {
  return (
    <>
      <main>{children}</main>
      <AppStyles />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
