import React from "react";
import styled from "styled-components";

const Container = styled.footer`
  color: #a0aec0;
  text-align: center;
  padding: 8px;
  a {
    color: var(--primary);
  }
  font-size: 12px;
  margin-bottom: env(safe-area-inset-bottom);
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: center;
  .divider {
    margin: 0 8px;
  }
`;
const Footer = () => (
  <>
    <Container>
      <div>&copy; {new Date().getFullYear()} CoLeague.app</div>
      <div>All Rights Reserved</div>
      <FlexContainer>
        <div>
          <a href="mailto:hello@coleague.app">hello@coleague.app</a>
        </div>
        <div className="divider">|</div>
        <div>
          <a href="https://twitter.com/coleagueapp">@coleagueapp</a>
        </div>
      </FlexContainer>
    </Container>
  </>
);

export default Footer;
